
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="medicine" class="col-12">
      <h5 class="mb-3">Medicine Details</h5>
      <div class="row g-4">
        <div class="col-md-8">
          <div class="card overflow-hidden">
            <div class="card-body">
              <div class="row gx-2 gx-lg-4">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <h4 class="lh-base mb-1">{{medicine.name}}</h4>
                      <div class=""><strong>Type:</strong> <span v-if="medicine.type"> {{medicine.type.title}}</span></div>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li @click.prevent="addAttribute('quantity')">
                        <button class="dropdown-item" type="button">
                          Add Quantity
                        </button>
                      </li>
                      <li @click.prevent="addAttribute('allocation')">
                        <button class="dropdown-item" type="button">
                          Create Allocation
                        </button>
                      </li>
                      <li @click.prevent="addAttribute('unit')">
                        <button class="dropdown-item" type="button">
                          Add New Unit
                        </button>
                      </li>
                      <li @click.prevent="popupModalShow = true">
                        <button class="dropdown-item" type="button">
                          Update Medicine
                        </button>
                      </li>
                      <li @click.prevent="deleteItem()">
                        <button class="dropdown-item" type="button">
                          Delete Medicine
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-lg-4">
                      <div class=""><strong>A unit name:</strong> <span>{{medicine.minimum_unit }}</span></div>
                    </div>
                    <div class="col-lg-4">
                      <div class=""><strong>Dosage form:</strong> <span>{{medicine.dosage_form }}</span></div>
                    </div>
                    <div class="col-lg-4">
                      <div class=""><strong>Concentration:</strong> <span>{{medicine.concentration }}</span></div>
                    </div>
                    <div class="col-12">
                      <label class="form-label fw-bold">Description</label>
                      <div v-html="preText(medicine.description)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-5">
            <div class="col-md-6">
              <statistic-apex-count
                title="AVAILABLE QUANTITY"
                :count-end="medicine.available_quantity"
                :total="medicine.quantity_received"
                :figure="medicine.available_quantity"
              />
            </div>
            <div class="col-md-6">
              <statistic-apex-count
                title="ALLOCATED QUANTITY"
                :count-end="medicine.quantity_allocated"
                :total="medicine.quantity_received"
                :figure="medicine.quantity_allocated"
                chart-color="#FFB02f"
              />
            </div>
          </div>
          
          <div class="pt-5">
            <h5>Received Quantities</h5>
            <medicine-quantities ref="medicineQuantities" :refresh="fetchItem" />
          </div>

          <div class="pt-5" v-if="medicine.statistics" >
            <h5>Allocation Statistics</h5>
            <div class="card">
              <div class="card-body">
                <allocation-report :data="medicine.statistics" />
              </div>
            </div>
          </div>

          <div class="pt-5">
            <h5>Medicine Allocations</h5>
            <medicine-allocations ref="medicineAllocations" :refresh="fetchItem" />
          </div>

          <div class="pt-5">
            <h5>Medicine Units</h5>
            <medicine-units ref="medicineUnits" :refresh="fetchItem" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky" style="top: 90px;">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Quantity Received</h6>
                <p class="text-uppercase h4 fw-bold mb-0">{{ $filters.number_format(medicine.quantity_received) }}</p>
              </div>
            </div>
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="mb-3">
                  <h6>Sub category</h6>
                  <p v-if="medicine.sub_category" class="mb-0">{{medicine.sub_category.title}}</p>
                </div>
                <div>
                  <h6>Category</h6>
                  <p v-if="medicine.category" class="text-uppercase mb-0">{{medicine.category.title}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" hide-footer centered
    title="Update medicine" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <medicine-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="medicine" :editMode="true"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import MedicineForm from '@/components/forms/MedicineForm.vue'
import AllocationReport from '@/components/widgets/AllocationReport.vue'
import MedicineAllocations from './MedicineAllocations.vue';
import MedicineQuantities from './MedicineQuantities.vue';
import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue'
import MedicineUnits from './MedicineUnits.vue';

export default {
  name: "medicine-show",
  components:{
    IsLoading,
    MedicineForm,
    AllocationReport,
    MedicineAllocations,
    MedicineQuantities,
    StatisticApexCount,
    MedicineUnits
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
    }
  },
  watch: {
    '$route.params.medicineId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    medicine(){
      return this.$store.state.medicine.item
    }
  },
  methods: {
    fetchItem(){
      this.$store.dispatch('medicine/fetchMedicine', this.$route.params.medicineId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          // this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You about to delete this medicine permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/medicines/${this.medicine.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('medicine/DELETE_ITEM', this.medicine.id)
              this.$router.push({path: '/medicines'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    },
    addAttribute(attribute){
      if(attribute == 'unit'){
        this.$refs.medicineUnits.initAdd()
      }
      if(attribute == 'allocation'){
        this.$refs.medicineAllocations.initAdd()
      }
      if(attribute == 'quantity'){
        this.$refs.medicineQuantities.initAdd()
      }
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

