
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="toggleFilters()">
                <button class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
              <li v-for="(sortOption, opIndex) in sortOrderOptions" :key="opIndex">
                <button  @click.prevent="setSortOrder(sortOption.value)" class="dropdown-item" type="button">
                  {{ sortOption.label }}
                </button>
              </li>
              <li @click.prevent="exportData()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <button @click.prevent="popupAddQuantity = true" type="button" class="btn btn-primary" >
            <i class="ri ri-add-fill align-bottom"></i> Add Quantity </button>
        </div>
        <div class="col-12">
          <div class="input-group mb-0">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="">
        <common-portlet headertitle="Filters"
         :visible="enableFilters" :open="true">
          <div class="loading-viewport">
            <div class="card-body p-lg-3">
              <allocated-item-filter-form 
              @startSearch="setFilters($event)"
              @resetFilters="initializeAll()" />
            </div>
          </div>
        </common-portlet>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-slot:cell(quantity)="data">
                  <div style="min-width:4rem;" class="">
                    {{ data.item.quantity }} <br>
                    <small>{{data.item.description}}</small>
                  </div>
                </template>
                <template v-slot:cell(package)="data">
                  <div style="min-width:4rem;" class="">
                    {{ data.item.unit_count }}-{{data.item.unit_title}}
                  </div>
                </template>
                <template v-slot:cell(receiver)="data">
                  <div v-if="data.item.receiver"
                    @click="navigateTo(data.item.receiver.id)"  class="d-flex cursor-pointer">
                  <img class="avatar rounded-circle me-2" :src="absoluteUrl(data.item.receiver.image)" />
                  <div>
                    <h6 class="mb-0">{{ data.item.receiver.first_name }} {{ data.item.receiver.last_name }}</h6>
                    <span>{{ data.item.receiver.mobile }}</span>
                  </div>
                </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="min-width:6rem;max-width:6rem;" class="">
                    {{ $filters.date(data.item.created_at, 1) }}
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
    <b-modal v-model="popupAddQuantity" hide-footer centered
      title="Add Medicine Quantity" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <medicine-quantity-form 
       v-if="popupAddQuantity" @closeMe="initializeAll()"
        @addItem="addItem($event)" :medicine="medicine"/>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
import IsLoading from '@/components/IsLoading.vue';
import AllocatedItemFilterForm from '@/components/forms/AllocatedItemFilterForm.vue';
import MedicineQuantityForm from '@/components/forms/MedicineQuantityForm.vue';

export default {
  name: "allocation-list",
  components:{
    IsLoading,
    CommonPortlet,
    AllocatedItemFilterForm,
    MedicineQuantityForm
  },
  props:{
    refresh: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      popupAddQuantity: false,
      enableFilters: false,
      queryFilters: null,
      searchQuery: "",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "package",
          label: "Package",
        },
        {
          key: "receiver",
          label: "Receiver",
        },
        {
          key: "created_at",
          label: "Date",
        },
      ],
      sortOrder: "created_at/desc",
      sortOrderOptions:[
        { value: 'created_at/desc', label: "Created Date (Desc)"},
        { value: 'created_at/asc', label: "Created Date (Asc)"},
        { value: 'quantity/desc', label: "Quantity (Desc)"},
        { value: 'quantity/asc', label: "Quantity (Asc)"},
      ],
      pageData: {
        data: []
      }
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    medicine(){
      return this.$store.state.medicine.item
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortOrder(){
      return { sort_column: this.sortOrder.split("/")[0], sort_direction: this.sortOrder.split("/")[1] }
    }
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    addItem(item){
      this.pageData.data.unshift(item);
      this.refresh()
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      if(!this.medicine.id) return;
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.getSortOrder, medicine_id: this.medicine.id }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$http.get(`/medicine-quantities?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    setSortOrder(order){
      this.sortOrder = order;
      this.fetchItems()
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    exportData() {
      const payload = {
        url: `/medicine-quantities/export-excel?medicine_id=${this.medicine.id}`,
        filename: 'medicine-quantities_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate medicine quantities');
          }
      })
    },
    initAdd(){
      this.popupAddQuantity = true  
    },
    initializeAll(){
      this.queryFilters = null;
      this.popupAddQuantity = false
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

