<template>
    <div class="">
        <div class="d-flex">
            <div class="me-5">
                <p class="mb-1 fw-medium">Total Allocated Quantity</p>
                <p class="h5 mb-0">{{ $filters.money_format(data.total_quantity, 0) }}</p>
            </div>
        </div>
        <apexchart class="apex-charts mx-n3" type="area"
        :options="areaChartOptions" height="266" :series="seriesData" />
    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props:{
        data:{
            default:() => ({
                total_quantity: 0,
                series:[]
            })
        }
    },
    data() {
        return {
            areaChartOptions: {
                chart: {
                    id: "vuechart-revenue-report",
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.3,
                        opacityTo: 0.9
                    }
                },
                legend: {
                    offsetY: 5
                },
                xaxis: {
                    type: "datetime"
                },
                grid: {
                    padding: {
                        bottom: 10
                    }
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            cssClass: 'text-grey fill-current',
                        },
                        formatter: function(val) {
                            return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                        }
                    }
                },
                colors: ["#1abc9c"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2],
                    curve: "smooth"
                }
            },
        };
    },
    computed:{
        seriesData(){
            return [
                {
                    name: "Total Quantity",
                    data: this.data.series.map((i) =>{
                        return [i.month, i.total_quantity]
                    })
                },
            ]
        }
    }
};
</script>


