
<template>
  <div>
    <div class="row gy-2">
      <div class="col-12">
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-lg-12 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li v-for="(sortOption, opIndex) in sortOrderOptions" :key="opIndex">
                  <button  @click.prevent="setSortOrder(sortOption.value)" class="dropdown-item" type="button">
                    {{ sortOption.label }}
                  </button>
                </li>
                <li @click.prevent="exportData()">
                  <button class="dropdown-item" type="button">
                    Export Excel
                  </button>
                </li>
              </ul>
            </div>
            <button @click.prevent="popupAllocation = true" type="button" class="btn btn-primary" >
              <i class="ri ri-add-fill align-bottom"></i> Create Allocation </button>
          </div>
          <div class="col-12">
            <div class="input-group mb-0">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-lg-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm" align="middle">
                <template v-slot:cell(friendly_id)="data">
                  <div style="min-width:2rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.friendly_id }}
                  </div>
                </template>
                <template v-slot:cell(quantity)="data">
                  <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.quantity }}
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ $filters.date(data.item.created_at, 1) }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal size="lg" v-model="popupAllocation" hide-footer fullscreen
      title="Create Allocation" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <allocation-create-form v-if="popupAllocation" @closeMe="initializeAll()"
        @addItem="addItem($event)" :medicine="medicine"/>
    </b-modal>
  </div>
  </template>
  
<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';
import AllocationCreateForm from '@/components/forms/AllocationCreateForm.vue';

export default {
  name: "medicine-allocations",
  props:{
    refresh: {
      type: Function,
      required: true
    }
  },
  components:{
    IsLoading,
    AllocationCreateForm
  },
  data() {
    return {
      isLoading: false,
      popupAllocation: false,
      searchQuery: "",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "friendly_id",
          label: "Number",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "created_at",
          label: "Date",
        }
      ],
      sortOrder: "created_at/desc",
      sortOrderOptions:[
        { value: 'created_at/desc', label: "Created Date (Desc)"},
        { value: 'created_at/asc', label: "Created Date (Asc)"},
        { value: 'quantity/desc', label: "Quantity (Desc)"},
        { value: 'quantity/asc', label: "Quantity (Asc)"},
      ],
      pageData: {
        data: []
      }
    }
  },
  computed:{
    medicine(){
      return this.$store.state.medicine.item
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortOrder(){
      return { sort_column: this.sortOrder.split("/")[0], sort_direction: this.sortOrder.split("/")[1] }
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/allocations/${id}`});
    },
    addItem(item){
      this.pageData.data.unshift(item);
      this.refresh()
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      if(!this.medicine.id) return;
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.getSortOrder,  medicine_id: this.medicine.id }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/allocations?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    setSortOrder(order){
      this.sortOrder = order;
      this.fetchItems()
    },
    exportData() {
      const payload = {
        url: `/allocations/export-excel?medicine_id=${this.medicine.id}`,
        filename: 'allocations_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate allocation');
          }
      })
    },
    initAdd(){
      this.popupAllocation = true  
    },
    initializeAll(){
      this.popupAllocation = false;
    }
  },
  mounted() {
    this.fetchItems()
  },
}

</script>
  
  