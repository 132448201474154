
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">
        <div class="col-md-6">
            <label for="local_government_id" class="form-label">Local government</label>
            <select @change="lgaChange()" v-model="data.local_government_id" 
                name="local_government_id" id="local_government_id" class="form-select">
                <option value="" > All LGA </option>
                <option v-for="(lga, coIndex) in lgaOptions" :key="coIndex" 
                :value="lga.id">{{lga.name}}</option>
            </select>
        </div>
        <div class="col-md-6">
            <label> Ward </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="wardOptions" 
            v-model="data.registration_area_id" placeholder="--select ward --"></multiselect>
        </div> 
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        IsLoading,
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            data: {
                local_government_id: "",
                registration_area_id: "",
            },
            mirrorData: null,
            wardOptions: [],
        }
    },
    computed:{
        lgaOptions(){
            return this.$store.state.localGovernments
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        fetchLgas(){
            this.isLoading = true;
            this.$store.dispatch('localGovernments')
                .then((response) => this.isLoading = false )
        },
        lgaChange(initialize = true){
            if(initialize){
                this.data.registration_area_id = "";
            }
            this.wardOptions = [];
            if(!this.data.local_government_id){ return }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    mounted(){
        this.fetchLgas()
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
