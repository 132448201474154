
<template>
  <div v-if="medicine">
    <div class="row gy-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-lg-3">
            <div class="mb-3">
              <button @click.prevent="popupModalShow = true" class="btn btn-primary" type="button">
                Create Unit
              </button>
            </div>
            <div class="table-responsive mb-0 loading-viewport">
              <b-table striped hover ref="selectableTable" :items="medicine.units"
                :fields="columns" responsive="sm" align="middle">
                <template v-slot:cell(title)="data">
                  <div style="min-width:2rem;" class="">
                    {{ data.item.title }}
                  </div>
                </template>
                <template v-slot:cell(value)="data">
                  <div style="min-width:5rem;" class="">
                    {{ data.item.value }}
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="min-width:7rem;" class="">
                    {{ $filters.date(data.item.created_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="initEdit(data.item)"
                        class="dropdown-item" type="button">
                          Edit unit
                        </button>
                      </li>
                      <li>
                        <button @click.prevent="deleteItem(data.item.id)"
                        class="dropdown-item" type="button">
                          Delete unit
                        </button>
                      </li>
                    </ul>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update medicine unit':'Add medicine unit'"
      title-class="fs-6" @hide="initializeAll()">
      <medicine-unit-form v-if="popupModalShow" 
      :medicineId="medicine.id" @closeMe="initializeAll()"
      :edit-mode="editMode" :edit-item="editItem"/>
    </b-modal>
  </div>
  </template>
  
<script>
import MedicineUnitForm from '@/components/forms/MedicineUnitForm.vue';
  
export default {
  name: "medicine-units",
  props:{
    refresh: {
      type: Function,
      required: true
    }
  },
  components:{
    MedicineUnitForm
  },
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
      columns: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "value",
          label: "Value",
        },
        {
          key: "created_at",
          label: "Date",
        },
        "action"
      ],
    }
  },
  computed:{
    medicine(){
      return this.$store.state.medicine.item
    },
  },
  methods: {
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You about to delete this unit permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/medicine-units/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('medicine/DELETE_UNIT', itemId)
            }
          })
        }
      });
    },
    initAdd(){
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
}

</script>
  
  