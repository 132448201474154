
<template>
<form autocomplete="off" class="">
  <div class="row gx-lg-4 g-3">
    <div class="col-sm-6">
      <label for="unit" class="form-label">Select Unit</label>
      <select @change="calculateQuantity()" v-model="selectedUnit" 
        :class="{ 'is-invalid': v$.data.unit_value.$error }"
        name="unit" id="unit" class="form-select">
      <option disabled :value="null" >--choose unit--</option>
          <option v-for="(unit, sIndex) in medicine.units" :key="sIndex" 
          :value="unit">{{unit.title}}</option>
      </select>
      <div v-for="(item, index) in v$.data.unit_value.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="inputUnitCount"> Unit Count </label>
      <input @change="calculateQuantity()" id="inputUnitCount" type="number" class="form-control" v-model="data.unit_count"
      :class="{ 'is-invalid': v$.data.unit_count.$error }" placeholder="Enter unit count" name="unit_count" />
      <div v-for="(item, index) in v$.data.unit_count.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div v-if="selectedUnit" class="col-12">
      <div class="alert small mb-0 alert-info">
        <strong>NOTE!</strong> One {{ selectedUnit.title }} is counted as {{ selectedUnit.value }} quantit{{ selectedUnit.value > 1 ? 'ies' : 'y' }}
      </div>
    </div>
    <div class="col-12">
      <label class="form-label" for="inputQuantity"> Total quantity </label>
      <input disabled id="inputQuantity" type="number" class="form-control" v-model="data.quantity"
      :class="{ 'is-invalid': v$.data.quantity.$error }" placeholder="Quantity" name="quantity" />
      <div v-for="(item, index) in v$.data.quantity.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-12">
      <label class="form-label">Description</label>
      <textarea v-model="data.description" class="form-control"
      rows="2" placeholder="Write Description" name="description"></textarea>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="mt-3">
        <b-button variant="primary" 
        @click.prevent="editMode? update() : confirmCreate()"
            type="button">{{editMode? 'Update':'Submit'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minValue, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props:{
    medicine:{
      type: Object,
      required: true 
    },
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      data: {
        medicine_id: this.medicine.id,
        quantity: 0,
        unit_count: 0,
        unit_value: null,
        unit_title: "",
        description: "",
      },
      selectedUnit: null
    }
  },
  validations() {
    return {
      data:{
        quantity: {
          required: helpers.withMessage("Quantity is required", required),
          minValue: minValue(1)
        },
        unit_count:{
          required: helpers.withMessage("Unit Count is required", required),
          minValue: minValue(1)
        },
        unit_value:{
          required: helpers.withMessage("Unit is required", required),
          minValue: minValue(1)
        }
      }
    }
  },
  methods: {
    confirmCreate(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.confirmAction({
          text: `You are about to receive: <h6>${this.data.unit_count}-${this.data.unit_title} package of ${this.medicine.name}</h6> Total quantity: 
          <h4>${this.$filters.number_format(this.data.quantity)}</h4> Ensure your details are accurate.`,
        }).then((result) => {
          if (result.value) { this.create(); }
        });
      })
    },
    create() {
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/medicine-quantities/create', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addItem', response.data.data)
            this.resetForm();
          }
        })
      })
    },
    calculateQuantity(){
      if(this.selectedUnit && this.data.unit_count){
        this.data.unit_title = this.selectedUnit.title
        this.data.unit_value = this.selectedUnit.value
        this.data.quantity = parseInt(this.data.unit_count) * parseInt(this.selectedUnit.value)
      } 
    },
    update(){
      const formData = new FormData()
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/medicine-quantities/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('updatedItem', response.data.data)
            this.resetForm()
          }
        })
    },
    resetForm() {
      this.$emit('closeMe')
    },
  },
  mounted(){
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
